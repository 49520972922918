// @mui material components
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import APIError from "components/ApiError";
import dateFormatter from "components/Formatter/DateFormatter";
import MDTypography from "components/MDTypography";

import dayExtractor from "components/Formatter/DayExtractor";
import { useEffect, useState } from "react";
import parentAPIService from "services/parentAPI-service";

function PayerStudentReport(props) {
  const { id, studentName } = props;

  const [rows, setRows] = useState([]);
  const [processing, setProcessing] = useState(true);
  const [apiError, setApiError] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = ["Date", "# of Classes", "Class Type", "Cost"];

  useEffect(() => {
    fetchStudentUnpaidClasses();
  }, [page, rowsPerPage]);

  const fetchStudentUnpaidClasses = async () => {
    setProcessing(true);
    setApiError(false);
    try {
      const studentPaymentsList =
        await parentAPIService.getStudentUnpaidClasses(
          id,
          rowsPerPage,
          page + 1
        );

      setRows(studentPaymentsList.data);
      setProcessing(false);
      setApiError(false);
    } catch (error) {
      setApiError(true);
      setProcessing(false);
    }
  };

  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            {/* Heading */}
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Report for {studentName}
              </MDTypography>
            </MDBox>

            {/* Progress Bar */}
            {processing && (
              <MDBox pt={2} px={2}>
                <LinearProgress
                  color="info"
                  variant="indeterminate"
                  sx={{ overflow: "hidden" }}
                />
              </MDBox>
            )}

            {/* No Data */}
            {!processing && !apiError && rows?.attendance?.length === 0 && (
              <MDBox pt={3} px={4} pb={5}>
                <MDTypography variant="h7" color="error">
                  No unpaid class record found
                </MDTypography>
              </MDBox>
            )}

            {/* Table */}
            {!processing && rows?.attendance?.length > 0 && (
              <MDBox>
                <MDBox display="flex flex-col" pt={3} px={2}>
                  <MDTypography
                    variant="h7"
                    fontWeight="regular"
                    fontStyle="italic"
                    color="secondary"
                  >
                    {!processing && rows && <span>{rows.message}</span>}
                  </MDTypography>
                </MDBox>
                {/* List */}
                <MDBox pt={3} px={1} pb={5}>
                  <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                    <Table>
                      {/* Header Columns */}
                      <TableHead>
                        <TableRow>
                          {columns.map((column, i) => (
                            <TableCell key={i}>{column}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      {/* Body Contents */}
                      <TableBody>
                        {rows.attendance.map((row, i) => (
                          <TableRow key={"unpaidClass" + i}>
                            <TableCell>
                              <MDTypography variant="h7" fontWeight="regular">
                                {dateFormatter(row.date)} (
                                {dayExtractor(row.date)})
                              </MDTypography>
                            </TableCell>
                            <TableCell>
                              <MDTypography variant="h7" fontWeight="regular">
                                {row.Number_of_classes}
                              </MDTypography>
                            </TableCell>
                            <TableCell>
                              <MDTypography variant="h7" fontWeight="regular">
                                {row.Class_type}
                              </MDTypography>
                            </TableCell>
                            <TableCell>
                              <MDTypography variant="h7" fontWeight="regular">
                                $ {row.cost_of_this_attendance}
                              </MDTypography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </MDBox>
              </MDBox>
            )}

            {/* Api Error */}
            {!processing && apiError && <APIError />}
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default PayerStudentReport;
