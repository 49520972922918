// @mui material components
import Card from "@mui/material/Card";
import { Grid } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";

// Material Dashboard 2 React example components
import {
  Icon,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import APIError from "components/ApiError";
import dateFormatter from "components/Formatter/DateFormatter";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import NotificationItem from "components/NotificationItem";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import colors from "../../assets/theme/base/colors";
import Footer from "../../components/Footer";
import MDInput from "../../components/MDInput";
import MDTypography from "../../components/MDTypography";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import DashboardLayout from "../../layouts/LayoutContainers/DashboardLayout";

import payerAPIService from "services/payerAPI-service";
import AddPayerDialog from "./addPayer";
import phoneNumberMask from "components/Formatter/PhoneMask";

function Payers() {
  const userSelector = useSelector((state) => state.auth);
  const [name, setName] = useState("");
  const [status, setStatus] = useState("Active");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [menu, setMenu] = useState(null);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [processing, setProcessing] = useState(true);
  const [apiError, setApiError] = useState(false);

  const [addPayerDialogOpen, setAddPayerDialogOpen] = useState(false);
  const [editPayerDialogOpen, setEditPayerDialogOpen] = useState(false);
  const [payerDetails, setPayerDetails] = useState([]);

  //Snack Bar
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  const handleNameSearch = (e) => {
    setPage(0);
    setName(e.target.value);
  };

  const handleOrderByChange = (e) => {
    setPage(0);
    setOrderBy(e.target.value);
  };

  const handleStatusChange = (e) => {
    setPage(0);
    setStatus(e.target.value);
  };

  const columns = ["Payor", "Associated Students", "Actions"];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      fetchPayerData(); // Call the API after the debounce delay
    }, 500); // 500ms debounce time

    return () => {
      clearTimeout(debounceTimer); // Clear the timeout if the dependencies change
    };
  }, [name, status, orderBy, page, rowsPerPage]);

  const closeMenu = () => setMenu(null);

  const renderActionMenu = (
    <Menu
      id="actions-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>
        <NotificationItem icon={<Icon>visibility</Icon>} title="View" />
      </MenuItem>
      <MenuItem onClick={closeMenu}>
        <NotificationItem icon={<Icon>delete</Icon>} title="Delete" />
      </MenuItem>
    </Menu>
  );

  const fetchPayerData = async () => {
    setProcessing(true);
    setApiError(false);
    try {
      const studentListResponse = await payerAPIService.getPayersList(
        rowsPerPage,
        page + 1,
        status,
        name,
        orderBy
      );
      setRows(studentListResponse.data.results);
      setTotal(studentListResponse.data.count);
      setProcessing(false);
    } catch (error) {
      setApiError(true);
      setProcessing(false);
    }
  };

  const handleAddPayer = () => {
    setAddPayerDialogOpen(true);
  };

  const handlePayerEdit = (payerDetails) => {
    setPayerDetails(payerDetails);
    setEditPayerDialogOpen(true);
  };

  const handleAddPayerDialogClose = (response) => {
    if (response === "Close") {
      setAddPayerDialogOpen(false);
    } else {
      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("Payer has been added.");
      openSnackBar();
      fetchPayerData();
      setAddPayerDialogOpen(false);
    }
  };

  const handleEditPayerDialogClose = (response) => {
    if (response === "Close") {
      setEditPayerDialogOpen(false);
    } else {
      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("Payer has been updated.");
      openSnackBar();
      fetchPayerData();
      setEditPayerDialogOpen(false);
    }
  };

  const renderAddPayerDialog = (
    <AddPayerDialog
      open={addPayerDialogOpen}
      onClose={handleAddPayerDialogClose}
    />
  );

  const renderEditPayerDialog = (
    <AddPayerDialog
      open={editPayerDialogOpen}
      onClose={handleEditPayerDialogClose}
      existingDetails={payerDetails}
    />
  );

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* Search Box */}
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        rowGap={1}
        columnGap={1}
      >
        {/* Input Search */}
        <Grid item md={4}>
          <MDInput
            placeholder="Search by name..."
            value={name}
            size="medium"
            onChange={handleNameSearch}
            sx={{
              width: 250,
              background: "white",
              borderRadius: "6px",
            }}
            type="search"
            InputProps={{
              startAdornment: (
                <Icon sx={{ color: "gray" }} fontSize="small">
                  search
                </Icon>
              ),
            }}
          />
        </Grid>

        <Grid
          item
          md={7}
          display={"flex"}
          justifyContent={"flex-end"}
          columnGap={"20px"}
        >
          {/* Order By  */}
          <Select
            id="orderBySelection"
            value={orderBy}
            displayEmpty
            onChange={handleOrderByChange}
            startAdornment={
              <InputAdornment position="start">
                <Icon color="info">sort</Icon>
              </InputAdornment>
            }
            variant="outlined"
            sx={{
              "& .MuiSelect-select.MuiSelect-select": {
                color: colors.info.main,
                fontWeight: 500,
                height: "2.8rem",
              },
              background: colors.white.main,
            }}
          >
            <MenuItem value={""}>Order By</MenuItem>
            <MenuItem value={"first_name"}>First Name - Ascending</MenuItem>
            <MenuItem value={"last_name"}>Last Name - Ascending</MenuItem>
          </Select>

          {/* Status */}
          <Select
            id="statusSelectLabel"
            value={status}
            displayEmpty
            onChange={handleStatusChange}
            startAdornment={
              <InputAdornment position="start">
                <Icon color="primary">filter_list</Icon>
              </InputAdornment>
            }
            variant="outlined"
            sx={{
              "& .MuiSelect-select.MuiSelect-select": {
                color:
                  status === ""
                    ? colors.info.main
                    : status === "Active"
                    ? colors.success.main
                    : colors.error.main,
                fontWeight: 500,
                height: "2.8rem",
              },
              background: colors.white.main,
            }}
          >
            <MenuItem value={""}>Status</MenuItem>
            <MenuItem value={"Active"}>Active</MenuItem>
            <MenuItem value={"Inactive"}>Inactive</MenuItem>
          </Select>
        </Grid>
      </Grid>

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {/* Heading */}
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Payors
                </MDTypography>
              </MDBox>
              {/* Add Button */}
              {Array.of("superadmin", "admin").includes(
                userSelector.userType
              ) && (
                <MDBox
                  mb={1}
                  mt={1}
                  pr={2}
                  display="flex"
                  justifyContent="end"
                  gap={2}
                  flexWrap="wrap"
                >
                  <MDButton
                    variant="contained"
                    color="dark"
                    type="button"
                    onClick={handleAddPayer}
                  >
                    + Add Payer
                  </MDButton>
                </MDBox>
              )}

              {/* Progress Bar */}
              {processing && (
                <MDBox pt={2} px={2}>
                  <LinearProgress
                    color="info"
                    variant="indeterminate"
                    sx={{ overflow: "hidden" }}
                  />
                </MDBox>
              )}
              {/* Table */}
              {!processing && total > 0 && (
                <MDBox pt={3} px={1} pb={5}>
                  <TableContainer component={Paper}>
                    <Table>
                      {/* Header Columns */}
                      <TableHead>
                        <TableRow>
                          {columns.map((column, i) => (
                            <TableCell key={i}>{column}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      {/* Body Contents */}
                      <TableBody>
                        {rows.map((row, index) => (
                          <TableRow key={"row" + index + row.id}>
                            {/* Name */}
                            <TableCell>
                              <Link to={`/payors/${row.uuid}`}>
                                <MDTypography
                                  variant="h7"
                                  fontWeight="bold"
                                  color="info"
                                >
                                  {row.first_name} {row.last_name}
                                </MDTypography>
                              </Link>
                              <br />
                              <MDTypography
                                variant="h7"
                                fontWeight="medium"
                                color="text"
                              >
                                {row.email}
                              </MDTypography>{" "}
                              <br />
                              <MDTypography
                                variant="h7"
                                fontWeight="medium"
                                color="text"
                              >
                                {phoneNumberMask(row.phone)}
                              </MDTypography>
                            </TableCell>

                            <TableCell>
                              <MDTypography
                                variant="h7"
                                fontWeight="bold"
                                color="text"
                              >
                                {row.associated_students.map(
                                  (payers, index) => (
                                    <MDBox
                                      key={"payer" + index}
                                      display="flex"
                                      flexDirection="column"
                                    >
                                      <MDTypography
                                        variant="h7"
                                        fontWeight="bold"
                                        color="text"
                                      >
                                        {payers?.student.first_name}{" "}
                                        {payers?.student.last_name}
                                      </MDTypography>
                                    </MDBox>
                                  )
                                )}
                              </MDTypography>
                            </TableCell>

                            {/* Action */}
                            <TableCell>
                              <Tooltip title="Edit Payer">
                                <MDButton
                                  iconOnly
                                  color="warning"
                                  variant="text"
                                  size="medium"
                                  onClick={() => handlePayerEdit(row)}
                                >
                                  <Icon>edit</Icon>
                                </MDButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    showFirstButton
                    showLastButton
                  />
                </MDBox>
              )}
              {/* No Data Found */}
              {!processing && !apiError && total === 0 && (
                <MDBox pt={3} px={4} pb={5}>
                  <MDTypography variant="h7" color="error">
                    No payor found
                  </MDTypography>
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {renderSnackBar}
      {renderActionMenu}
      {renderAddPayerDialog}
      {renderEditPayerDialog}
      {/* API Error */}
      {!processing && apiError && <APIError />}
      <Footer />
    </DashboardLayout>
  );
}

export default Payers;
