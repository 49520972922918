import DashboardNavbar from "components/Navbars/DashboardNavbar";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
// @mui material components
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import { Grid } from "@mui/material";
import Icon from "@mui/material/Icon";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

// Material Dashboard 2 React components
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import rerportBGImage from "assets/images/bg1.jpg";
import parentAttendance from "assets/images/parent_bg2.jpg";
import paymentBGImage from "assets/images/payment.jpg";
import avatar from "assets/images/person.png";
import PayerStudentPaymentDetails from "./paymentDetails";
import ParentStudentAttendanceDetails from "./attendanceDetails";
import PayerStudentReport from "./reports";

function ParentStudentDetails() {
  let { id } = useParams();
  const [searchParams] = useSearchParams();

  // Extract the studentName parameter
  const studentName = searchParams.get("studentName");
  const unpaidAmount = searchParams.get("unpaidAmount");

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState("attendance");

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="14.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({
              functions: { rgba, linearGradient },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.2),
                rgba(gradients.info.state, 0.2)
              )}, url(${
                tabValue === "attendance"
                  ? parentAttendance
                  : tabValue === "payments"
                  ? paymentBGImage
                  : rerportBGImage
              })`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDAvatar
                src={avatar}
                alt="profile-image"
                size="xl"
                shadow="sm"
              />
            </Grid>
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h4" fontWeight="medium">
                  {studentName}
                </MDTypography>
                {unpaidAmount && unpaidAmount > 0 && (
                  <MDTypography
                    variant="button"
                    color="error"
                    fontWeight="bold"
                  >
                    Unpaid Amount : ${unpaidAmount}
                  </MDTypography>
                )}
                <MDTypography
                  variant="button"
                  color="text"
                  fontWeight="regular"
                >
                  <br />
                  Student
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={8} lg={7} sx={{ ml: "auto" }}>
              <AppBar position="static">
                <Tabs
                  orientation={tabsOrientation}
                  value={tabValue}
                  onChange={handleSetTabValue}
                >
                  {/* <Tab
                    value="schedule"
                    label="Schedule"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        lock_clock
                      </Icon>
                    }
                  /> */}
                  <Tab
                    value="attendance"
                    label="Attendance"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        calendar_month
                      </Icon>
                    }
                  />
                  <Tab
                    value="payments"
                    label="Payments"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        paid
                      </Icon>
                    }
                  />
                  <Tab
                    value="report"
                    label="Report"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        description
                      </Icon>
                    }
                  />
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>

          {/* Views */}
          <MDBox mt={5} width="100%">
            {tabValue === "payments" ? (
              <PayerStudentPaymentDetails id={id} studentName={studentName} />
            ) : tabValue === "attendance" ? (
              <ParentStudentAttendanceDetails
                id={id}
                studentName={studentName}
              />
            ) : (
              <PayerStudentReport id={id} studentName={studentName} />
            )}
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default ParentStudentDetails;
