import axiosInstance from "./htttp.service";

class ParentApiService {
  axiosInstances = axiosInstance;

  getMyAssociatedStudents = async (id) => {
    return await this.axiosInstances.get(`/payment/payer/student-details`);
  };

  getStudentPaymentHistory = async (id, pageSize, pageIndex) => {
    return await this.axiosInstances.get(
      `payment/payer/student-payment-history/?student_UUID=${id}&page_size=${pageSize}&page=${pageIndex}`
    );
  };

  getStudentAttendanceHistory = async (id, pageSize, pageIndex) => {
    return await this.axiosInstances.get(
      `payment/payer/student-attendance/?student_UUID=${id}&page_size=${pageSize}&page=${pageIndex}`
    );
  };

  getStudentUnpaidClasses = async (id, pageSize, pageIndex) => {
    return await this.axiosInstances.get(`payment/unpaid-classes/${id}`);
  };
}

export default new ParentApiService();
