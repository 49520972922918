import { useEffect, useState } from "react";

import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { forwardRef } from "react";
import paymentAPIService from "services/paymentAPI-service";
import payerAPIService from "services/payerAPI-service";
import InputMask from "react-input-mask";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function AddPayerDialog(props) {
  const { open, onClose, existingDetails } = props;

  const [processing, setProcessing] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  const initialState = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    zipcode: "",
  };

  const [inputs, setInputs] = useState({
    ...initialState,
  });

  const [errors, setErrors] = useState({
    first_name: false,
    last_name: false,
    email: false,
    phone: false,
    zipcode: false,
  });

  const handleCancel = () => {
    onClose("Close");
    setInputs({ ...initialState });
    setProcessing(false);
  };

  useEffect(() => {
    if (existingDetails?.uuid) {
      setInputs({
        first_name: existingDetails?.first_name,
        last_name: existingDetails?.last_name,
        phone: existingDetails?.phone,
        email: existingDetails?.email,
        zipcode: existingDetails?.zip_code,
      });
    }
  }, [existingDetails, open]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if everything has been input
    if (!inputs.first_name) {
      setErrors({ ...errors, first_name: true });
      return;
    } else {
      setErrors({ ...errors, first_name: false });
    }

    if (!inputs.last_name) {
      setErrors({ ...errors, last_name: true });
      return;
    } else {
      setErrors({ ...errors, last_name: false });
    }

    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!inputs.email || inputs.email.trim().length === 0) {
      setErrors({ ...errors, email: true });
      return;
    } else {
      if (!inputs.email.trim().match(mailFormat)) {
        setErrors({ ...errors, email: true });
        return;
      } else {
        setErrors({ ...errors, email: false });
      }
    }

    if (!inputs.phone || inputs.phone.trim().length != 10) {
      setErrors({ ...errors, phone: true });
      return;
    } else {
      setErrors({ ...errors, phone: false });
    }

    if (!inputs.zipcode) {
      setErrors({ ...errors, zipcode: true });
      return;
    } else {
      setErrors({ ...errors, zipcode: false });
    }

    // Now that everything is verified
    setProcessing(true);
    try {
      let payload = {
        first_name: inputs.first_name,
        last_name: inputs.last_name,
        phone: inputs.phone,
        email: inputs.email,
        zipcode: inputs.zipcode,
      };

      if (existingDetails?.uuid) {
        const response = await payerAPIService.updatePayer(
          payload,
          existingDetails.uuid
        );
        setProcessing(false);
        setInputs({ ...initialState });
        onClose("success");
      } else {
        const response = await payerAPIService.addPayer(payload);
        setProcessing(false);
        setInputs({ ...initialState });
        onClose("success");
      }
    } catch (error) {
      setSnackBarColor("error");
      setSnackBarIcon("warning");
      setSnackBarTitle("Error");
      setSnackBarMessage("Failed to add the Payer.");
      openSnackBar();
      setProcessing(false);
    }
  };

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography color="info" fontWeight="bold">
          {existingDetails ? "Edit" : "Add New"} Payor
        </MDTypography>
        <MDButton iconOnly onClick={handleCancel} disabled={processing}>
          <Icon fontSize="large">cancel</Icon>
        </MDButton>
      </DialogTitle>
      <DialogContent>
        {/* Name */}
        <MDBox
          variant="contained"
          component="form"
          role="form"
          method="POST"
          onSubmit={handleSubmit}
          mt={2}
        >
          <MDBox p={2} shadow="md" mb={4}>
            <MDBox display="flex" flexDirection="row" columnGap={2}>
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  <span style={{ color: "red" }}>*</span> First Name
                </MDTypography>
                <MDInput
                  type="text"
                  value={inputs.first_name}
                  name="first_name"
                  onChange={changeHandler}
                  error={errors.first_name}
                  fullWidth
                />
              </MDBox>
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  <span style={{ color: "red" }}>*</span> Last Name
                </MDTypography>
                <MDInput
                  type="text"
                  value={inputs.last_name}
                  name="last_name"
                  onChange={changeHandler}
                  error={errors.last_name}
                  fullWidth
                />
              </MDBox>
            </MDBox>

            <MDBox
              mb={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              width="100%"
            >
              <MDTypography variant="h7" color="text" fontWeight="bold">
                <span style={{ color: "red" }}>*</span> Email
              </MDTypography>
              <MDInput
                type="email"
                value={inputs.email}
                name="email"
                onChange={changeHandler}
                error={errors.email}
                fullWidth
              />
            </MDBox>

            <MDBox display="flex" flexDirection="row" columnGap={2}>
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  <span style={{ color: "red" }}>*</span> Phone
                </MDTypography>

                <MDInput
                  type="text"
                  value={inputs.phone}
                  name="phone"
                  onChange={changeHandler}
                  error={errors.phone}
                  fullWidth
                  inputProps={{
                    maxLength: 10,
                  }}
                />
              </MDBox>

              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  <span style={{ color: "red" }}>*</span> Zip Code
                </MDTypography>
                <MDInput
                  type="email"
                  value={inputs.zipcode}
                  name="zipcode"
                  onChange={changeHandler}
                  error={errors.zipcode}
                  fullWidth
                />
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>

        <DialogActions>
          {/* Form Here */}
          <MDButton
            variant="contained"
            color="light"
            onClick={handleCancel}
            disabled={processing}
          >
            No
          </MDButton>
          <MDButton
            variant="contained"
            color="info"
            onClick={handleSubmit}
            disabled={processing}
          >
            Submit{" "}
            {processing && (
              <CircularProgress
                size={14}
                sx={{ marginLeft: 1 }}
                color="warning"
              />
            )}
          </MDButton>
        </DialogActions>
      </DialogContent>
      {renderSnackBar}
    </Dialog>
  );
}

export default AddPayerDialog;
