import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Icon from "@mui/material/Icon";
import Slide from "@mui/material/Slide";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { forwardRef, useEffect, useState } from "react";
import payerAPIService from "services/payerAPI-service";
import studentAPIService from "services/studentAPI-service";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function BulkMessage(props) {
  const { open, onClose } = props;

  // Get students schedule by day
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const daysMap = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  };

  // Search Student By Name
  const [selectedDay, setSelectedDay] = useState("");
  const [customMessage, setCustomMessage] = useState("");
  const [name, setName] = useState("");
  const [apiError, setApiError] = useState(false);
  const [searchProcessing, setSearchProcessing] = useState(false);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);

  const [selectedStudents, setSelectedStudents] = useState([]);
  const columns = ["Student Name", "Associated Payors", "Action"];
  // For this form
  const [processing, setProcessing] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  const handleCancel = () => {
    onClose("Close");
    setSelectedDay(null);
    setSelectedStudents([]);
    setProcessing(false);
  };

  // Searching for Students
  const handleNameSearch = (e) => {
    setName(e.target.value);
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      fetchStudentsList(); // Call the API after the debounce delay
    }, 500); // 500ms debounce time

    return () => {
      clearTimeout(debounceTimer); // Clear the timeout if the dependencies change
    };
  }, [name, selectedDay]);

  const fetchStudentsList = async () => {
    if (!selectedDay) return;
    setSearchProcessing(true);
    setApiError(false);

    try {
      const response =
        await studentAPIService.getStudentsPayersScheduleListByDayAndName(
          daysMap[selectedDay],
          name
        );
      setRows(response.data.results);
      setTotal(response.data.count);

      setSearchProcessing(false);
    } catch (error) {
      setApiError(true);
      setSearchProcessing(false);
    }
  };

  const selectStudent = (row, condition) => {
    if (condition) {
      setSelectedStudents([...selectedStudents, row]);
    } else {
      let existingStudents = selectedStudents.filter(
        (student) => student.id != row.id
      );
      setSelectedStudents([...existingStudents]);
    }
  };

  const isStudentSelected = (row) => {
    let payerSelected = false;

    selectedStudents.forEach((payer) => {
      if (payer.id === row.id) {
        payerSelected = true;
      }
    });

    return payerSelected;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!customMessage || customMessage.length == 0) {
      setSnackBarColor("warning");
      setSnackBarIcon("error");
      setSnackBarTitle("Message Required");
      setSnackBarMessage("Please enter the message that you want to send.");
      openSnackBar();
      return;
    }

    // Check if everything has been input
    if (!selectedStudents || selectedStudents.length == 0) {
      return;
    }

    // Now that everything is verified
    let payerUUIDS = new Set();
    selectedStudents.forEach((student) => {
      if (student.student.payers) {
        student.student.payers.forEach((payer) => {
          payerUUIDS.add(payer.uuid);
        });
      }
    });
    payerUUIDS = [...payerUUIDS];

    setProcessing(true);

    try {
      let payload = {
        message: customMessage,
        payer_uuids: payerUUIDS,
      };
      const response = await payerAPIService.sendCustomMessage(payload);
      setProcessing(false);
      setCustomMessage("");
      setSelectedStudents([]);
      onClose("success");
    } catch (error) {
      setSnackBarColor("error");
      setSnackBarIcon("warning");
      setSnackBarTitle("Error");
      setSnackBarMessage("Failed to send the message.");
      openSnackBar();
      setProcessing(false);
    }
  };

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  return (
    <Dialog
      slots={{ transition: Transition }}
      open={open}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography color="info" fontWeight="bold">
          Send Bulk Custom Message
        </MDTypography>
        <MDButton iconOnly onClick={handleCancel} disabled={processing}>
          <Icon fontSize="large">cancel</Icon>
        </MDButton>
      </DialogTitle>
      <DialogContent>
        <MDBox
          variant="contained"
          component="form"
          role="form"
          method="POST"
          onSubmit={handleSubmit}
          mt={2}
        >
          <MDBox p={2} shadow="md" mb={4}>
            {/* Message */}
            <MDBox
              mb={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              width="100%"
            >
              <MDTypography variant="h7" color="text" fontWeight="regular">
                <span style={{ color: "red" }}>*</span> Message
              </MDTypography>
              <MDInput
                type="text"
                value={customMessage}
                name="message"
                onChange={(e) => setCustomMessage(e.target.value)}
                fullWidth
                multiline
                minRows="3"
              />
            </MDBox>

            <MDBox
              mb={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              width="100%"
            >
              <MDTypography variant="h7" color="text" fontWeight="regular">
                <span style={{ color: "red" }}>*</span> Select the day to view
                students based on their schedule
              </MDTypography>
              <Select
                id="selectedDay"
                value={selectedDay}
                name="selectedDay"
                onChange={(e) => {
                  setSelectedDay(e.target.value);
                }}
                fullWidth
                sx={{
                  "& .MuiSelect-icon": {
                    display: "flex",
                  },
                  height: "2.8rem",
                }}
              >
                {days.map((day, index) => (
                  <MenuItem key={day} value={day}>
                    {day}
                  </MenuItem>
                ))}
              </Select>
            </MDBox>

            {/* //  Search Payer */}
            <MDBox
              mb={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              width="100%"
            >
              <MDTypography variant="h7" color="text" fontWeight="regular">
                <span style={{ color: "red" }}>*</span> Please use the search
                box below to find the students
              </MDTypography>
              <MDInput
                placeholder="Search Student by name..."
                value={name}
                size="medium"
                onChange={handleNameSearch}
                sx={{
                  width: "100%",
                  background: "white",
                  borderRadius: "6px",
                }}
                type="search"
                InputProps={{
                  startAdornment: (
                    <Icon sx={{ color: "gray" }} fontSize="small">
                      search
                    </Icon>
                  ),
                }}
              />
            </MDBox>

            {/* Progress Bar */}
            {searchProcessing && (
              <LinearProgress
                color="info"
                variant="indeterminate"
                sx={{ overflow: "hidden" }}
              />
            )}

            {/* No Data */}
            {!searchProcessing && !apiError && total == 0 && (
              <MDBox>
                <MDTypography variant="h7" color="error">
                  No student found
                </MDTypography>
              </MDBox>
            )}

            {/* Students */}
            {!searchProcessing && !apiError && total > 0 && (
              <MDBox pb={5}>
                <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                  <Table>
                    {/* Header Columns */}
                    <TableHead>
                      <TableRow>
                        {columns.map((column, i) => (
                          <TableCell key={i}>{column}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    {/* Body Contents */}
                    <TableBody>
                      {rows.map((row, index) => (
                        <TableRow key={row.id + " " + index}>
                          <TableCell>
                            <MDTypography
                              variant="h7"
                              fontWeight="bold"
                              color="text"
                            >
                              {row.student.first_name} {row.student.last_name}
                            </MDTypography>
                          </TableCell>

                          <TableCell>
                            {row.student?.payers.map((payer, index) => (
                              <MDTypography
                                key={"payer" + payer.uuid}
                                variant="h7"
                                fontWeight="regular"
                                color="text"
                              >
                                {payer.first_name} {payer.last_name}
                                <br />
                              </MDTypography>
                            ))}
                          </TableCell>

                          <TableCell>
                            {/* If the student is not selected */}
                            {!isStudentSelected(row) && (
                              <MDButton
                                type="button"
                                variant="contained"
                                color="info"
                                size="small"
                                onClick={() => selectStudent(row, true)}
                              >
                                Select
                              </MDButton>
                            )}
                            {/* If the student is selected */}
                            {isStudentSelected(row) && (
                              <MDButton
                                type="button"
                                variant="contained"
                                color="error"
                                size="small"
                                onClick={() => selectStudent(row, false)}
                              >
                                Remove
                              </MDButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </MDBox>
            )}
          </MDBox>
        </MDBox>

        {/* Selected Count */}
        <MDTypography variant="h7" color="text" fontWeight="bold" mb={2}>
          {selectedStudents.length} students selected
        </MDTypography>

        <DialogActions>
          {/* Form Here */}
          <MDButton
            variant="contained"
            color="light"
            onClick={handleCancel}
            disabled={processing}
          >
            No
          </MDButton>
          <MDButton
            variant="contained"
            color="info"
            onClick={handleSubmit}
            disabled={processing}
          >
            Submit{" "}
            {processing && (
              <CircularProgress
                size={14}
                sx={{ marginLeft: 1 }}
                color="warning"
              />
            )}
          </MDButton>
        </DialogActions>
      </DialogContent>
      {renderSnackBar}
    </Dialog>
  );
}

export default BulkMessage;
